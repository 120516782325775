<template>
  <AppDialogLayoutToFollowTheEssence
    :can-use="canFollowPage"
    :filtered-workspaces-list="filteredWorkspacesListForFollowing"
    :follow-menu="followMenu"
    :followed-ids="followedIds"
    :is-show-title-icon="showIcon"
    :selected-workspaces="selectedWorkspaces"
    class="PAGE-FOLLOW"
    data-test="follow_page_button"
    text-header-follow="Follow or Un-Follow Pages"
    text-to-disabled="You can't follow your own page into its original workspace"
    text-top-input="Select or un-select workspaces to follow or un-follow pages"
    title="Follow"
    @on-done="followItemInWorkspaces"
    @update-dialog="followMenu = $event"
    @update-selected-workspaces="selectedWorkspaces = $event" />
</template>
<script>
import AppDialogLayoutToFollowTheEssence from '@/components/App/AppDialogLayoutToFollowTheEssence';
import MyPageApi from '@/services/graphql/myPage';
import {
  mapMutations,
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';
export default {
  name: 'PageFollowWithWsSelection',
  components: {
    AppDialogLayoutToFollowTheEssence,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    followMenu: false,
    selectedWorkspaces: [],
    followedIds: [],
  }),
  computed: {
    ...mapGetters('UserRoles', ['canFollowPage']),
    ...mapGetters('FeatureFlags', ['showUpdatesToWorkspaceGuests']),
    ...mapState('Workspace', ['workspacesListForFollowing', 'activeWorkspaceId']),
    followedPageId() {
      const { id } = this.item || {
      };
      return id;
    },
    followedWsId() {
      const { workspaceId: id } = this.item || {
      };
      return id;
    },
    filteredWorkspacesListForFollowing() {
      const { followedPageId } = this;
      return this.workspacesListForFollowing.map(({ id, ...rest } = {
      }) => ({
        ...rest,
        id,
        disabled: id === followedPageId && !this.showUpdatesToWorkspaceGuests,
      }));
    },
    isAutoAddCurrentWsToSelected() {
      const { activeWorkspaceId: id, followedWsId } = this;
      return id !== followedWsId;
    },
  },
  watch: {
    followMenu: {
      async handler(val) {
        if (!val) {
          this.followedIds = [];
          this.selectedWorkspaces = [];
          return;
        }
        const { spinner } = this;
        spinner(true);
        await this.getWorkspacesListForFollowing();
        await this.getListWorkspacesWithFollowedPage();
        spinner(false);
      },
    },
  },
  methods: {
    ...mapActions({
      handleError: 'handleError',
      followPage: 'MyPages/followPage',
      unfollowPage: 'MyPages/unfollowPage',
      getWorkspacesListForFollowing: 'Workspace/getWorkspacesListForFollowing',
    }),
    ...mapMutations(['spinner']),
    setSelectedWorkspaces(ids = []) {
      const { isAutoAddCurrentWsToSelected: isAdd, activeWorkspaceId: id } = this;
      if (!isAdd) {
        this.selectedWorkspaces = ids;
        return;
      }
      const isSelectedCurrentWs = ids.some(wsId => wsId === id);
      if (isSelectedCurrentWs) {
        this.selectedWorkspaces = ids;
        return;
      }
      this.selectedWorkspaces = [id, ...ids];
    },
    async getListWorkspacesWithFollowedPage() {
      try {
        const { followedPageId: pageId } = this;
        const { data } = await MyPageApi.listWorkspacesWithFollowedPage({
          variables: {
            pageId,
          },
          responseTemplate: 'data { workspaceId }',
        });
        const { data: workspaces = [] } = data?.response || {
        };
        const wsIds = workspaces.map(({ workspaceId } = {
        }) => workspaceId);
        this.setSelectedWorkspaces(wsIds);
        this.followedIds = wsIds;
      } catch (err) {
        this.handleError(err);
      }
    },
    async followItemInWorkspaces({ saveFollowedIds, saveUnfollowedIds } = {
    }) {
      const { spinner } = this;
      try {
        spinner(true);
        const { followedPageId: pageId, followedPageId: pageWorkspaceId } = this;
        if (saveFollowedIds?.length) {
          saveFollowedIds.length && await Promise.all(saveFollowedIds.map(async workspaceId => await this.followPage({
            workspaceId,
            pageId,
            pageWorkspaceId,
          })));
        }
        if (saveUnfollowedIds?.length) {
          saveUnfollowedIds.length && await Promise.all(saveUnfollowedIds.map(async workspaceId => await this.unfollowPage({
            workspaceId,
            pageId,
          })));
        }
        this.followMenu = false;
      } catch (err) {
        this.handleError(err);
      } finally {
        spinner(false);
      }
    },
  },
};
</script>