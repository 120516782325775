<template>
  <div class="d-flex justify-center">
    <div class="workspace-page__header">
      <!-- images -->
      <div class="workspace-page__image">
        <ListingImage
          :pictures="setPictures(workspacePage)"
          :collection="workspacePage" />
      </div>

      <!-- title -->
      <div class="workspace-page__title font-fam-poppins mt-6">
        {{ workspacePage.name }}
      </div>

      <!-- description -->
      <div
        v-if="workspaceDescription"
        class="workspace-page__description mt-3">
        {{ workspaceDescription }}
      </div>

      <!-- action btns -->
      <div
        v-if="!hideFollow"
        class="workspace-page__actions mt-8">
        <!-- follow -->
        <PageFollowWithWsSelection
          :show-icon="false"
          :item="workspacePage" />
        <!-- suggest followers -->
        <SuggestWorkspacePageDialog
          :workspace-page-id="workspacePage.id"
          :page-workspace-id="workspacePage.workspaceId" />
      </div>
    </div>
  </div>
</template>
<script>
import ListingImage from '@/components/Listing/ListingImageItem/ListingImage';
import PageFollowWithWsSelection from '@/components/Collections/CollectionsActions/CollectionsActionsComponent/PageFollow/PageFollowWithWsSelection';
import SuggestWorkspacePageDialog from '@/components/App/AppSuggestDialog/SuggestWorkspacePageDialog';

import SetPictures from '@/mixins/SetPictures';

export default {
  name: 'WorkspacePageHeader',
  components: {
    ListingImage,
    PageFollowWithWsSelection,
    SuggestWorkspacePageDialog,
  },
  mixins: [SetPictures],
  props: {
    workspacePage: {
      type: Object,
      default: null,
      required: true,
    },
    hideFollow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    workspaceDescription() {
      const { description } = this.workspacePage ?? {
      };
      if (!description) return '';
      return description;
    },
  },
};
</script>
<style scoped lang="scss">
.workspace-page {
  &__header {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 850px;
  }

  &__image {
    height: 115px;
    width: 175px;
    border-radius: 10px;
    box-shadow: 0px 0px 25px 0px rgba(34, 34, 34, 0.15);
    overflow: hidden;
  }

  &__title {
    color: var(--v-black-base);
    font-size: 42px;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
  }

  &__description {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 12px;
  }
}
</style>
