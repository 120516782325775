<template>
  <div class="action-footer__container">
    <app-action-btn
      class="pointer"
      wrapper-class="text-uppercase fs-14 ma-0 d-flex align-center"
      @click="openShareLink">
      <template #iconWrapper>
        <img
          src="@/assets/icons/link-icon.svg"
          class="mr-2"
          height="20"
          width="20">
      </template>
      Quick view link
    </app-action-btn>
    <app-dialog
      v-model="dialog"
      :value.sync="dialog"
      width="620px"
      persistent
      content-class="v-dialog__form"
      @keydown.esc="dialog = false ">
      <div class="dialog-content">
        <v-card>
          <HeaderShareAdvanced @closeModal="dialog = false">
            <template #textTitle>
              Share a Quick View Link
            </template>
            <template #subtitle>
              Create visibility outside Sourcery
            </template>
          </HeaderShareAdvanced>
          <input-shared-link
            text-copied="Link copied for sending"
            :url="url"
            class="mt-3">
            <template #bottom-text>
              Quick View Link visible to all - Copy the link and send by email or embed in presentations, Social platforms, etc.
            </template>
            <template #append-icon>
              <app-icon-btn
                :access="accessUseShare"
                bottom
                show-tooltip
                :icon-color=" isHasFullAccessUseShare ? 'red' : ''"
                icon-height="20"
                class="pointer"
                icon-name="mdi-cached"
                @click.stop="resetLinkByIcon" />
            </template>
          </input-shared-link>
          <v-card-text>
            <template v-if="!isPageLink">
              <v-row class="mb-1">
                <v-col
                  class="pt-0"
                  cols="12">
                  <v-tooltip
                    :disabled="isHasFullAccessUseShare"
                    bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on">
                        <v-switch
                          v-model="mode"
                          :disabled="!isHasFullAccessUseShare "
                          color="blue"
                          hide-details
                          :input-value="mode"
                          label="Restrict certain fields from external link"
                          @change="changeMode" />
                      </div>
                    </template>
                    <span>{{ NOT_PERMITTED_ACCESS }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row
                v-if="mode"
                class="mt-0">
                <v-col
                  class="ml-5 pt-0 overflow-hidden"
                  cols="12">
                  <div
                    style="max-height: 250px"
                    class="overflow-auto px-3">
                    <template
                      v-for="item in fieldsToShow">
                      <v-tooltip
                        :key="item.id"
                        :disabled="isHasFullAccessUseShare"
                        bottom>
                        <template #activator="{ on, attrs }">
                          <div
                            v-bind="attrs"
                            v-on="on">
                            <v-switch
                              v-model="selectedFieldsToShow"
                              :disabled="requiredFields.includes(item.id) || !isHasFullAccessUseShare"
                              color="blue"
                              class="type-field"
                              :label="item.name"
                              :value="item.id" />
                          </div>
                        </template>
                        <span>{{ NOT_PERMITTED_ACCESS }}</span>
                      </v-tooltip>
                    </template>
                  </div>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
          <v-card-actions
            class="justify-end">
            <v-btn
              outlined
              @click="dialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="blue"
              class="white--text"
              elevation="0"
              @click="updateSharedLink">
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </app-dialog>
  </div>
</template>
<script>
import { copyToClipBoard } from '@/utils';
import {
  mapState,
  mapMutations,
  mapActions, mapGetters,
} from 'vuex';
import InputSharedLink from '@/components/App/AppSharedLink';
import HeaderShareAdvanced from '@/components/CollectionsLibrarysRelocate/HeaderShareAdvanced';
import { NOT_PERMITTED_ACCESS } from '@/constants/userPermissions';
// #constants
const RESET_TEXT = 'Do you want to reset Quick View Link for people outside Sourcery? This action will override the previous link. You CANNOT UNDO this action.';
export default {
  name: 'CollectionShareLink',
  components: {
    HeaderShareAdvanced,
    InputSharedLink,
  },
  props: {
    isPageLink: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      url: '',
      selectedFieldsToShow: [],
      mode: false,
      NOT_PERMITTED_ACCESS,
    };
  },
  computed: {
    ...mapGetters('UserRoles', ['findUserRoleInLibraryForActiveHeader', 'canUseSharedLink']),
    ...mapGetters('Collections', ['collectionsSchema']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('Collections', ['sharedLink', 'updateCollectionSharedLink', 'updatePageQuickViewLink']),
    requiredFields() {
      return ['D', 'C'];
    },
    fieldsToShow() {
      return this.collectionsSchema?.schema ? this.collectionsSchema?.schema.map(({ id, text: name }) => ({
        id,
        name,
      })) : [];
    },
    accessUseShare() {
      return this.canUseSharedLink(this.findUserRoleInLibraryForActiveHeader);
    },
    isHasFullAccessUseShare() {
      const access = this.accessUseShare;
      return access?.allowed ?? false;
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.selectedFieldsToShow = this.requiredFields;
        this.mode = false;
      }
    },
  },
  methods: {
    ...mapMutations(['spinner', 'openSnackBar']),
    ...mapActions(['handleError']),
    ...mapActions('Collections', ['getSharedLink', 'resetSharedLink', 'resetPageQuickViewLink', 'getPageQuickViewLink', 'getWorkspaceSharedLink']),
    changeMode(value) {
      if (value) this.selectedFieldsToShow = this.fieldsToShow.map(e => e.id);
    },
    initFields(mode, fields) {
      if (mode !== 'restricted') return false;
      this.mode = true;
      const { requiredFields } = this;
      fields = [...requiredFields, ...fields.filter(e => !requiredFields.includes(e))];
      this.selectedFieldsToShow = fields;
    },
    async openShareLink() {
      if (!this.findUserRoleInLibraryForActiveHeader && !this.isPageLink) {
        await this.getUrlShareLink(true);
        copyToClipBoard(this.url, 'Link copied for sending');
        return false;
      }
      this.selectedFieldsToShow = this.requiredFields;
      await this.getUrlShareLink();
      this.dialog = true;
    },
    getUrlShareLink: async function (onlyUrl = false) {
      const { activeWorkspaceId: workspaceId } = this;
      const params = {
        collectionId: this.$route.params.id,
        workspaceId,
      };
      this.spinner(true);
      try {
        if (!this.isPageLink) {
          await this.getPageQuickViewLink(params);
        } else {
          params.pageId = this.$route.params.pageId;
          params.workspaceId = this.$route.params.pageId;
          await this.getWorkspaceSharedLink(params);
        }
        if (this.sharedLink) {
          const { url, mode, fieldsToShow } = this.sharedLink;
          this.url = url;
          !onlyUrl && this.initFields(mode, fieldsToShow);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async resetLinkByIcon() {
      if (!this.isHasFullAccessUseShare) return false;
      const response = await this.$openConfirm({
        text: RESET_TEXT,
      });
      response && await this.onResetSharedLink();
    },
    async onResetSharedLink() {
      const params = {
        workspaceId: this.activeWorkspaceId,
      };
      if (this.isPageLink) {
        params.pageId = this.$route.params.pageId;
        params.workspaceId = this.$route.params.pageId;
      } else {
        params.collectionId = this.$route.params.id;
      }
      this.spinner(true);
      try {
        this.url = this.isPageLink ?
          await this.resetSharedLink(params) :
          await this.resetPageQuickViewLink(params);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.spinner(false);
      }
    },
    async updateSharedLink() {
      if (!this.isHasFullAccessUseShare) {
        this.dialog = false;
        return false;
      }
      const { mode, selectedFieldsToShow: fieldsToShow } = this;
      const params = {
        collectionId: this.$route.params.id,
        mode: mode ? 'restricted' : 'full',
        workspaceId: this.activeWorkspaceId,
        ...(mode && {
          fieldsToShow,
        }),
      };
      this.spinner(true);
      try {
        if (this.isPageLink) {
          this.updatePageQuickViewLink(params);
        } else {
          this.updateCollectionSharedLink(params);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
        this.dialog = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-dialog__form .v-card__text {
  padding-top: 10px !important;
}
.type-field.v-input--selection-controls {
  margin-top: 4px !important;
}
</style>
