<template>
  <SuggestDialog
    :is-icon="isIcon"
    :show-in-kebab-action="showInKebabAction"
    :disabled="disabled"
    :suggest-handler="suggestHandler" />
</template>
<script>
import {
  TYPE_MEMBER,
  TYPE_CREATOR,
  NOT_PERMITTED_ACCESS,
} from '@/constants/userPermissions';
import {
  mapGetters, mapActions,
} from 'vuex';
import SuggestDialog from '@/components/App/AppSuggestDialog';
export default {
  name: 'SuggestWorkspacePageDialog',
  components: {
    SuggestDialog,
  },
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    workspacePageId: {
      type: String,
      default: null,
    },
    pageWorkspaceId: {
      type: String,
      default: null,
    },
    showInKebabAction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('Workspace', ['getActiveWorkspaceMembership']),
    disabled() {
      let allowed = this.getActiveWorkspaceMembership == TYPE_MEMBER
                      || this.getActiveWorkspaceMembership == TYPE_CREATOR;
      if (allowed) {
        return {
          allowed: true,
        };
      } else {
        return {
          allowed: false,
          reason: NOT_PERMITTED_ACCESS,
        };
      }
    },
  },
  methods: {
    ...mapGetters('Workspace', ['getActiveWorkspace']),
    ...mapActions('MyPages', ['suggestWorkspacePage']),
    async suggestHandler({ selectedUsers, emailMessage }) {
      this.suggestWorkspacePage({
        selectedUsers: selectedUsers,
        emailMessage,
        workspaceId: this.getActiveWorkspace().id,
        workspacePageId: this.workspacePageId,
        pageWorkspaceId: this.pageWorkspaceId,
      });
    },
  },
};
</script>