<template>
  <app-dialog
    v-model="dialog"
    :value.sync="dialog"
    width="637"
    content-class="v-dialog__form">
    <template #activator="{ on }">
      <app-action-btn
        v-if="isIcon"
        :access="disabled"
        :show-tooltip="!disabled || !disabled.allowed"
        top
        v-on="disabled.allowed && on">
        <template #iconWrapper>
          <v-icon
            size="20">
            mdi-message-bulleted
          </v-icon>
        </template>
        <span>
          Suggest Followers
        </span>
      </app-action-btn>
      <app-action-btn
        v-else-if="!showInKebabAction"
        :access="disabled"
        data-test="suggest_followers_button"
        wrapper-class="ma-0"
        top
        :show-tooltip="!disabled || !disabled.allowed"
        v-on="disabled.allowed && on">
        <v-btn
          outlined>
          Suggest Followers
        </v-btn>
      </app-action-btn>
      <app-btn
        v-else
        :access="disabled"
        :show-tooltip="!disabled || !disabled.allowed"
        btn-class="white--text"
        color="blue"
        class="mr-2"
        elevation="0"
        top
        v-on="disabled.allowed && on">
        Suggest Followers
      </app-btn>
    </template>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center">
          Suggest to people and groups
        </div>
        <div
          class="sourcery__icon-wrapper black"
          @click="dialog = false">
          <v-icon
            color="white"
            size="15">
            mdi-close
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text v-if="dialog">
        <ShareAutocomplete
          collection-share
          check-email-errors
          invite-to-message="Press enter to send the suggestion"
          shared-autocomplete
          @selected="selected">
          <template #name="{ onSave }">
            <p @click="onSave" />
          </template>
        </ShareAutocomplete>
        <p
          v-if="showValidationEmailErrors && validationEmailErrors.length"
          class="mt-2 mb-2 fs-14">
          {{ ErrorsText.WRONG_EMAILS_FOR_SHARING(validationEmailErrors) }}
        </p>
        <v-textarea
          v-model="emailMessage"
          class="c-textarea"
          placeholder="Message" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          outlined
          @click="dialog = false">
          Cancel
        </v-btn>
        <v-btn
          class="white--text"
          color="blue"
          elevation="0"
          @click="save">
          Send
        </v-btn>
      </v-card-actions>
    </v-card>
  </app-dialog>
</template>
<script>
import ShareAutocomplete from '@/components/App/AppShareAutocomplete';
import ErrorsText from '@/constants/errors';
import { isValidEmail } from '@/utils';
import { debounce } from 'lodash';
export default {
  name: 'SuggestDialog',
  components: {
    ShareAutocomplete,
  },
  props: {
    isIcon: {
      type: Boolean,
      default: false,
    },
    showInKebabAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Object,
      default: () => {},
    },
    suggestHandler: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      emailMessage: '',
      selectedUsers: [],
      showValidationEmailErrors: false,
      ErrorsText,
    };
  },
  computed: {
    validationEmailErrors() {
      const changedUsers = [this.selectedUsers];
      if (!changedUsers.length) {
        return [];
      }
      return changedUsers.flatMap(users => users.filter(user => !isValidEmail(user)));
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.selectedUsers = [];
        this.showValidationEmailErrors = false;
        this.emailMessage = '';
      }
    },
  },
  methods: {
    selected(data) {
      this.selectedUsers = data;
    },
    save: debounce(async function() {
      if (this.validationEmailErrors.length) {
        this.showValidationEmailErrors = true;
        return;
      }
      this.showValidationEmailErrors = false;
      this.suggestItemsHandler();
    }, 0),
    extractEmail(user) {
      return typeof user === 'string' ? user : user?.email;
    },
    async suggestItemsHandler() {
      this.dialog = false;
      this.suggestHandler({
        selectedUsers: this.selectedUsers,
        emailMessage: this.emailMessage,
      });
    },
  },
};
</script>
