/**
 * Icon utils as Methods version without caching
 */
import {
  isObject, omit, isString,
} from 'lodash';
export default {
  methods: {
    getSafe(item, prop) {
      if (!item) {
        return null;
      }
      if (isString(item)) {
        return item;
      }
      return item[prop];
    },
    getProps(item, redundantProps = []) {
      if (!isObject(item)) {
        return {
        };
      }
      if (!redundantProps.length) {
        return item;
      }
      if (!Array.isArray(redundantProps)) {
        throw new Error('Please provide redundantProps as array');
      }
      return omit(item, redundantProps);
    },
  },
};
