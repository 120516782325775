import { mapActions } from 'vuex';
export default {
  methods: {
    ...mapActions('Collections', ['ignoreSuggestion']),
    async manageActions({ action = null, item = null }) {
      const allowedActionsToRequest = ['ignoreSuggestion'];
      if (!action || !item || !allowedActionsToRequest.includes(action)) {
        return;
      }
      await this[action](item);
    },
  },
};
