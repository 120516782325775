<template>
  <Collections
    is-community-collections
    hide-column-members
    is-shared-page
    :is-workspace-edit-page="isWorkspaceEditPage"
    :is-workspace-page="isWorkspacePage"
    :expanded-collections="expandedCollections"
    :filtered-list-of-collections="filteredListOfCollections" />
</template>
<script>
import {
  mapGetters, mapMutations,
} from 'vuex';

import {
  ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE,
} from '@/constants';

import Collections from '@/components/Collections';

export default {
  name: 'SharedWorkspaceDetails',
  components: {
    Collections,
  },
  props: {
    isWorkspacePage: {
      type: Boolean,
      default: false,
    },
    isWorkspaceEditPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expandedCollections: {
      },
    };
  },
  computed: {
    ...mapGetters({
      getCollectionsGroups: 'Collections/getAppropriateCollectionsGroupsByCriteria',
      getCommunityCollectionGroup: 'Collections/getCommunityCollectionGroup',
    }),
    routeName() {
      return [ROUTE_COMMUNITY_COLLECTIONS, ROUTE_WORKSPACE_PAGE];
    },
    filteredListOfCollections() {
      const { getCollectionsGroups, routeName } = this;
      if (this.isWorkspacePage) {
        return [this.getCommunityCollectionGroup];
      }
      return getCollectionsGroups({
        routeName,
      });
    },
  },
  mounted() {
    this.updateActiveElement({
      kind: 'default',
    });
  },
  methods: {
    ...mapMutations(['updateActiveElement']),
  },
};
</script>
