<!-- This is shown when the component is still loading for the initial render -->
<template>
  <div class="pt-16">
    <v-skeleton-loader
      class="d-flex justify-end justify-sm-space-between flex-column flex-sm-row w-100"
      type="button@2" />
    <CollectionListingSectionSkeleton />
  </div>
</template>

<script>
import CollectionListingSectionSkeleton from '@/components/App/AppSkeleton/Collections/CollectionListingSectionSkeleton';
export default {
  name: 'CollectionsBaseSkeleton',
  components: {
    CollectionListingSectionSkeleton,
  },
};
</script>

<style scoped lang="scss">
  ::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
    .v-skeleton-loader__button {
      margin-top: 12px;
      width: 160px;
      height: 28px;
      align-self: end;
    }
  }
</style>