<template>
  <div class="sourcery__grid mt-12">
    <AppCardSkeleton
      v-for="n in 4"
      :key="n"
      class="pa-3 flex-grow-1 mx-0" />
  </div>
</template>

<script>
import AppCardSkeleton from '@/components/App/AppCardSkeleton';
export default {
  name: 'CollectionsListingSectionSkeleton',
  components: {
    AppCardSkeleton,
  },
};
</script>
